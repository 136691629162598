<template>
  <div>
    <LoginReg />
    <Header />
    <!-- <AboutTeam />
    <MarkeMarker />
    <GoodCompany /> -->
  </div>
</template>

<script>
import Header from '@/components/Header/index'
import LoginReg from '@/components/LoginRegister/index'
// import AboutTeam from '@/components/AboutTeam/index'
// import MarkeMarker from '@/components/Marker/index'
// import GoodCompany from '@/components/GoodCompany/index'
export default {
  data() {
    return {}
  },

  components: { Header, LoginReg},
  created() {},
  mounted() {},
}
</script>
<style scoped>
/* @import url(); 引入css类 */
</style>
