<template>
  <div class="box">
    <div class="content">
      <div class="contentIn">
        <p>Accelerating Crypto Markets</p>
        <p>
          U-trading automated proprietary quantitative trading software provides
          24/7 liquidity to 100+ crypto assets across 15+ centralized spot and
          derivative crypto exchanges.
        </p>
        <!-- <button class="getIn">
          <span class="text">Get in touch</span>
        </button> -->
      </div>
    </div>
    <video
      src="https://gravityteam.co/wp-content/uploads/2022/07/hero-animation.mp4"
      muted="muted"
      autoplay="autoplay"
      loop="true"
    ></video>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  mounted() {},
}
</script>
<style scoped lang="less">
.box {
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  .content {
    width: 100%;
    color: #fff;
    text-align: center;
    padding-top: 80px;
    .contentIn {
      width: 570px;
      margin: 0 auto;
      p:nth-child(1) {
        font-size: 80px;
      }
      p:nth-child(2) {
        padding-top: 40px;
        font-size: 20px;
        line-height: 1.5;
      }
      .getIn {
        font-size: 40px;
        display: inline-flex;
        justify-content: center;
        position: relative;
        padding: 18px 26px;
        border: 0;
        border-radius: 2px;
        outline: 0;
        background: rgba(0, 0, 0, 0);
        text-align: center;
        overflow: hidden;
        margin-top: 60px;
        .text {
          position: relative;
          z-index: 2;
          color: #fff;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.1;
          text-transform: uppercase;
        }
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          border-radius: 2px;
          background: linear-gradient(
            135deg,
            #665dcd 0%,
            #5fa4e6 44.76%,
            #d2ab67 100%
          );
          transition: transform 0.4s;
        }
      }
    }
  }
  video {
    width: 100%;
    height: auto;
  }
}
</style>
